var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", app: "", color: "white", light: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.menu($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pr-2 pl-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-silverware-fork-knife")
              ])
            ],
            1
          ),
          _c("v-toolbar-title", [
            _c(
              "span",
              { staticStyle: { color: "#84264f", "font-weight": "bold" } },
              [_vm._v("Menús")]
            ),
            _vm._v(" / Añadir menú\n    ")
          ])
        ],
        1
      ),
      _vm.error$.isError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(_vm._s(_vm.error$.message))
          ])
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v("\n    Hay campos que faltan por seleccionar\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v("Vale")]
          )
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("v-date-picker", {
            attrs: {
              multiple: "",
              locale: "es-es",
              "first-day-of-week": 1,
              landscape: true,
              "full-width": true,
              "allowed-dates": _vm.allowedDates,
              color: "primary"
            },
            on: { "click:date": _vm.save },
            model: {
              value: _vm.dates,
              callback: function($$v) {
                _vm.dates = $$v
              },
              expression: "dates"
            }
          }),
          _vm.dates.length
            ? _c(
                "v-tabs",
                {
                  staticClass: "mt-6",
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab", { attrs: { href: "#vegan" } }, [
                    _vm._v("Vegano")
                  ]),
                  _c(
                    "v-tab",
                    {
                      attrs: { href: "#flexible" },
                      on: { click: _vm.onFlexTabClicked }
                    },
                    [_vm._v("Flexible")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.dates.length
            ? _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { key: 1, attrs: { value: "vegan" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "veganForm",
                          staticClass: "px-10",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _vm._l(_vm.veganMenus, function(day, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getUserFriendlyDate(_vm.dates[index])
                                    )
                                  )
                                ]),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Desayuno",
                                            items: _vm.recipesBreakfast$.filter(
                                              function(ref) {
                                                var vegan = ref.vegan

                                                return vegan
                                              }
                                            ),
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.breakfast_id,
                                            callback: function($$v) {
                                              _vm.$set(day, "breakfast_id", $$v)
                                            },
                                            expression: "day.breakfast_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Comida - Primer plato",
                                            items: _vm.recipesLunch$.filter(
                                              function(ref) {
                                                var vegan = ref.vegan

                                                return vegan
                                              }
                                            ),
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            required: "",
                                            rules: _vm.requiredRule(
                                              "Comida - Primer Plato"
                                            ),
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.lunch_starter_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                day,
                                                "lunch_starter_id",
                                                $$v
                                              )
                                            },
                                            expression: "day.lunch_starter_id"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Comida - Segundo plato",
                                            items: _vm.recipesLunch$.filter(
                                              function(ref) {
                                                var vegan = ref.vegan

                                                return vegan
                                              }
                                            ),
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            required: "",
                                            rules: _vm.requiredRule(
                                              "Comida - Segundo Plato"
                                            ),
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.lunch_id,
                                            callback: function($$v) {
                                              _vm.$set(day, "lunch_id", $$v)
                                            },
                                            expression: "day.lunch_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Merienda",
                                            items: _vm.recipesSnack$.filter(
                                              function(ref) {
                                                var vegan = ref.vegan

                                                return vegan
                                              }
                                            ),
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.snack_id,
                                            callback: function($$v) {
                                              _vm.$set(day, "snack_id", $$v)
                                            },
                                            expression: "day.snack_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Cena - Primer plato",
                                            items: _vm.recipesDinner$.filter(
                                              function(ref) {
                                                var vegan = ref.vegan

                                                return vegan
                                              }
                                            ),
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            required: "",
                                            rules: _vm.requiredRule(
                                              "Cena - Primer Plato"
                                            ),
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.dinner_starter_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                day,
                                                "dinner_starter_id",
                                                $$v
                                              )
                                            },
                                            expression: "day.dinner_starter_id"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Cena - Segundo plato",
                                            items: _vm.recipesDinner$.filter(
                                              function(ref) {
                                                var vegan = ref.vegan

                                                return vegan
                                              }
                                            ),
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            clearable: "",
                                            required: "",
                                            rules: _vm.requiredRule(
                                              "Cena - Segundo Plato"
                                            )
                                          },
                                          model: {
                                            value: day.dinner_id,
                                            callback: function($$v) {
                                              _vm.$set(day, "dinner_id", $$v)
                                            },
                                            expression: "day.dinner_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: 2, attrs: { value: "flexible" } },
                    [
                      _c(
                        "v-form",
                        { ref: "veganForm", staticClass: "px-10" },
                        [
                          _vm._l(_vm.flexibleMenus, function(day, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getUserFriendlyDate(_vm.dates[index])
                                    )
                                  )
                                ]),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Desayuno",
                                            items: _vm.recipesBreakfast$,
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.breakfast_id,
                                            callback: function($$v) {
                                              _vm.$set(day, "breakfast_id", $$v)
                                            },
                                            expression: "day.breakfast_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Comida - Primer plato",
                                            items: _vm.recipesLunch$,
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            required: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.lunch_starter_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                day,
                                                "lunch_starter_id",
                                                $$v
                                              )
                                            },
                                            expression: "day.lunch_starter_id"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Comida - Segundo plato",
                                            items: _vm.recipesLunch$,
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            required: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.lunch_id,
                                            callback: function($$v) {
                                              _vm.$set(day, "lunch_id", $$v)
                                            },
                                            expression: "day.lunch_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Merienda",
                                            items: _vm.recipesSnack$,
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.snack_id,
                                            callback: function($$v) {
                                              _vm.$set(day, "snack_id", $$v)
                                            },
                                            expression: "day.snack_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Cena - Primer plato",
                                            items: _vm.recipesDinner$,
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            required: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: day.dinner_starter_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                day,
                                                "dinner_starter_id",
                                                $$v
                                              )
                                            },
                                            expression: "day.dinner_starter_id"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex",
                                        attrs: { cols: "12", sm: "6" }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            label: "Cena - Segundo plato",
                                            items: _vm.recipesDinner$,
                                            "item-text": "title",
                                            "item-value": "id",
                                            outlined: "",
                                            clearable: "",
                                            required: ""
                                          },
                                          model: {
                                            value: day.dinner_id,
                                            callback: function($$v) {
                                              _vm.$set(day, "dinner_id", $$v)
                                            },
                                            expression: "day.dinner_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-block mt-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-white",
                  attrs: { color: "secondary", to: "/menus" }
                },
                [
                  _c("v-icon", { attrs: { left: "", dark: "" } }, [
                    _vm._v("mdi-arrow-left")
                  ]),
                  _vm._v("Volver\n      ")
                ],
                1
              ),
              _vm.dates.length
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        depressed: "",
                        type: "button",
                        color: "success"
                      },
                      on: {
                        click: function($event) {
                          return _vm.create()
                        }
                      }
                    },
                    [_vm._v("Guardar")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }