<template>
  <div>
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <div class="pr-2 pl-2">
        <v-icon color="primary">mdi-silverware-fork-knife</v-icon>
      </div>
      <v-toolbar-title>
        <span style="color:#84264f; font-weight:bold">Menús</span> / Editar menú
      </v-toolbar-title>
    </v-app-bar>

    <v-alert v-if="error$.isError" type="error">{{ error$.message }}</v-alert>

    <v-snackbar v-model="updatedSuccess">
      Menú guardado correctamente
      <v-btn color="pink" text @click="updatedSuccess = false">Cerrar</v-btn>
    </v-snackbar>

    <v-snackbar v-model="snackbar">
      Hay campos que faltan por seleccionar
      <v-btn color="pink" text @click="snackbar = false">Vale</v-btn>
    </v-snackbar>

    <v-container fluid v-if="dates.length">
      <h3 class="mb-4">Semana menú</h3>
      <v-date-picker
        color="primary"
        v-model="dates"
        multiple
        @click:date="save"
        locale="es-es"
        :first-day-of-week="1"
        :landscape="true"
        :full-width="true"
        :allowed-dates="allowedDates"
      ></v-date-picker>
      <v-tabs class="mt-6" v-model="tab">
        <v-tab href="#vegan">Vegano</v-tab>
        <v-tab href="#flexible">Flexible</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" value="vegan">
          <v-form
            v-if="dates.length"
            ref="form"
            class="px-10"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <div v-for="(menu, index) in veganMenus" :key="index">
              <h3>{{ getUserFriendlyDate(dates[index]) }}</h3>
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesBreakfast$.filter(({ vegan }) => vegan)"
                    label="Desayuno"
                    item-text="title"
                    item-value="id"
                    v-model="menu.breakfast_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesLunch$.filter(({ vegan }) => vegan)"
                    label="Comida - Primer plato"
                    item-text="title"
                    item-value="id"
                    v-model="menu.lunch_starter_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesLunch$.filter(({ vegan }) => vegan)"
                    label="Comida - Segundo plato"
                    item-text="title"
                    item-value="id"
                    v-model="menu.lunch_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesSnack$.filter(({ vegan }) => vegan)"
                    label="Merienda"
                    item-text="title"
                    item-value="id"
                    v-model="menu.snack_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesDinner$.filter(({ vegan }) => vegan)"
                    label="Cena - Primer plato"
                    item-text="title"
                    item-value="id"
                    v-model="menu.dinner_starter_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesDinner$.filter(({ vegan }) => vegan)"
                    label="Cena - Segundo plato"
                    item-text="title"
                    item-value="id"
                    v-model="menu.dinner_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-tab-item>
        <v-tab-item :key="2" value="flexible">
          <v-form
            v-if="dates.length"
            ref="form"
            class="px-10"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <div v-for="(menu, index) in flexibleMenus" :key="index">
              <h3>{{ getUserFriendlyDate(dates[index]) }}</h3>
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesBreakfast$"
                    label="Desayuno"
                    item-text="title"
                    item-value="id"
                    v-model="menu.breakfast_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesLunch$"
                    label="Comida - Primer plato"
                    item-text="title"
                    item-value="id"
                    v-model="menu.lunch_starter_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesLunch$"
                    label="Comida - Segundo plato"
                    item-text="title"
                    item-value="id"
                    v-model="menu.lunch_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesSnack$"
                    label="Merienda"
                    item-text="title"
                    item-value="id"
                    v-model="menu.snack_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesDinner$"
                    label="Cena - Primer plato"
                    item-text="title"
                    item-value="id"
                    v-model="menu.dinner_starter_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    @change="updateMenus()"
                    :items="recipesDinner$"
                    label="Cena - Segundo plato"
                    item-text="title"
                    item-value="id"
                    v-model="menu.dinner_id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
      <v-btn class="text-white mt-4" color="secondary" to="/menus">
        <v-icon left dark>mdi-arrow-left</v-icon>Volver
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import { getMenu } from "@/services/menus";
import {
  getWeekStartAndEndDates,
  getUserFriendlyDate,
} from "@/utils/weekCalendarDays";

export default {
  data() {
    return {
      dates: [],
      tab: "vegan",
      veganMenus: [],
      flexibleMenus: [],
      updatedSuccess: false,
      valid: false,
      lazy: true,
      snackbar: false,
      getUserFriendlyDate,
    };
  },
  computed: {
    recipes$() {
      return store.getters["recipes/items"].map((recipe) => ({
        title: recipe.title,
        id: recipe.id,
        vegan: recipe.vegan,
      }));
    },
    getAlreadyCreatedMenus() {
      return store.getters["menus/items"];
    },
    recipesBreakfast$() {
      return store.getters["recipes/items"]
        .filter((recipe) => recipe.is_breakfast)
        .map((recipe) => ({
          title: recipe.title,
          id: recipe.id,
          vegan: recipe.vegan,
        }));
    },
    recipesLunch$() {
      return store.getters["recipes/items"]
        .filter((recipe) => recipe.is_lunch)
        .map((recipe) => ({
          title: recipe.title,
          id: recipe.id,
          vegan: recipe.vegan,
        }));
    },
    recipesSnack$() {
      return store.getters["recipes/items"]
        .filter((recipe) => recipe.is_snack)
        .map((recipe) => ({
          title: recipe.title,
          id: recipe.id,
          vegan: recipe.vegan,
        }));
    },
    recipesDinner$() {
      return store.getters["recipes/items"]
        .filter((recipe) => recipe.is_dinner)
        .map((recipe) => ({
          title: recipe.title,
          id: recipe.id,
          vegan: recipe.vegan,
        }));
    },
    error$() {
      return store.getters["menus/error"];
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    allowedDates(val) {
      let disabledDates = [];
      for (let date of this.getAlreadyCreatedMenus) {
        disabledDates = [
          ...disabledDates,
          ...getWeekStartAndEndDates(date.first_date, true),
        ];
      }
      const day = new Date(val).getDay();
      const isWeekend = day === 6 || day === 0;
      return (
        (!disabledDates.includes(val) || this.dates.includes(val))
      );
    },
    async load() {
      const { data } = await getMenu(this.$route.params.id);
      this.dates = [...getWeekStartAndEndDates(data[0].week_monday)];
      this.veganMenus = [{}, {}, {}, {}, {}, {}, {}].map((value, index) => {
        value.vegan = true;
        const found = data.find(
          (dataValue) => dataValue.vegan && dataValue.date === this.dates[index]
        );
        return { ...value, ...found };
      });
      this.flexibleMenus = [{}, {}, {}, {}, {}, {}, {}].map((value, index) => {
        value.vegan = false;
        const found = data.find(
          (dataValue) =>
            !dataValue.vegan && dataValue.date === this.dates[index]
        );
        return { ...value, ...found };
      });
    },
    menu() {
      store.dispatch("user/ToggleMenu");
    },
    updateMenus() {
      if (this.$refs.form.validate()) {
        this.valid = true;
        this.snackbar = false;

        this.$store
          .dispatch("menus/UpdateMenu", {
            menus: [...this.veganMenus, ...this.flexibleMenus],
            dates: [...this.dates, ...this.dates],
          })
          .then(async () => {
            this.updatedSuccess = true;
            await this.load();
            setTimeout(() => {
              this.updatedSuccess = false;
            }, 2000);
          })
          .catch(() => {
            alert("No ha sido posible crear el menu.");
          });
      } else {
        this.valid = false;
        this.snackbar = true;
      }
    },
    save(date) {
      this.dates = [...getWeekStartAndEndDates(date)];
      this.updateMenus();
    },
  },
};
</script>

<style></style>
