import moment from 'moment';

export const getWeekStartAndEndDates = (date, addWeekEnd) => {
  addWeekEnd = true;
  const today = moment(date);
  const firstDayOfWeek = today.startOf('week');

  const countDays = addWeekEnd ? 6 : 4

  const dates = [firstDayOfWeek.format('YYYY-MM-DD')];

  for (let i = 0; i < countDays; ++i) {
    dates.push(firstDayOfWeek.add(1, 'day').format('YYYY-MM-DD'));
  }


  return dates;
};


export const getUserFriendlyDate = (date) => {
  return moment(date).format("dddd D");
}
