<template>
  <div>
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <div class="pr-2 pl-2">
        <v-icon color="primary">mdi-silverware-fork-knife</v-icon>
      </div>
      <v-toolbar-title>
        <span style="color:#84264f; font-weight:bold">Menús</span> / Añadir menú
      </v-toolbar-title>
    </v-app-bar>

    <v-alert v-if="error$.isError" type="error">{{ error$.message }}</v-alert>

    <v-snackbar v-model="snackbar">
      Hay campos que faltan por seleccionar
      <v-btn color="pink" text @click="snackbar = false">Vale</v-btn>
    </v-snackbar>

    <v-container fluid>
      <v-date-picker
        v-model="dates"
        multiple
        @click:date="save"
        locale="es-es"
        :first-day-of-week="1"
        :landscape="true"
        :full-width="true"
        :allowed-dates="allowedDates"
        color="primary"
      ></v-date-picker>
      <v-tabs class="mt-6" v-model="tab" v-if="dates.length">
        <v-tab href="#vegan">Vegano</v-tab>
        <v-tab href="#flexible" @click="onFlexTabClicked">Flexible</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" v-if="dates.length">
        <v-tab-item :key="1" value="vegan">
          <v-form ref="veganForm" class="px-10" v-model="valid" lazy-validation>
            <template>
              <div v-for="(day, index) in veganMenus" :key="index">
                <h3>{{ getUserFriendlyDate(dates[index]) }}</h3>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.breakfast_id"
                      label="Desayuno"
                      :items="recipesBreakfast$.filter(({ vegan }) => vegan)"
                      item-text="title"
                      item-value="id"
                      outlined
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.lunch_starter_id"
                      label="Comida - Primer plato"
                      :items="recipesLunch$.filter(({ vegan }) => vegan)"
                      item-text="title"
                      item-value="id"
                      outlined
                      required
                      :rules="requiredRule('Comida - Primer Plato')"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.lunch_id"
                      label="Comida - Segundo plato"
                      :items="recipesLunch$.filter(({ vegan }) => vegan)"
                      item-text="title"
                      item-value="id"
                      outlined
                      required
                      :rules="requiredRule('Comida - Segundo Plato')"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.snack_id"
                      label="Merienda"
                      :items="recipesSnack$.filter(({ vegan }) => vegan)"
                      item-text="title"
                      item-value="id"
                      outlined
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.dinner_starter_id"
                      label="Cena - Primer plato"
                      :items="recipesDinner$.filter(({ vegan }) => vegan)"
                      item-text="title"
                      item-value="id"
                      outlined
                      required
                      :rules="requiredRule('Cena - Primer Plato')"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.dinner_id"
                      label="Cena - Segundo plato"
                      :items="recipesDinner$.filter(({ vegan }) => vegan)"
                      item-text="title"
                      item-value="id"
                      outlined
                      clearable
                      required
                      :rules="requiredRule('Cena - Segundo Plato')"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-form>
        </v-tab-item>
        <v-tab-item :key="2" value="flexible">
          <v-form ref="veganForm" class="px-10">
            <template>
              <div v-for="(day, index) in flexibleMenus" :key="index">
                <h3>{{ getUserFriendlyDate(dates[index]) }}</h3>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.breakfast_id"
                      label="Desayuno"
                      :items="recipesBreakfast$"
                      item-text="title"
                      item-value="id"
                      outlined
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.lunch_starter_id"
                      label="Comida - Primer plato"
                      :items="recipesLunch$"
                      item-text="title"
                      item-value="id"
                      outlined
                      required
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.lunch_id"
                      label="Comida - Segundo plato"
                      :items="recipesLunch$"
                      item-text="title"
                      item-value="id"
                      outlined
                      required
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.snack_id"
                      label="Merienda"
                      :items="recipesSnack$"
                      item-text="title"
                      item-value="id"
                      outlined
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.dinner_starter_id"
                      label="Cena - Primer plato"
                      :items="recipesDinner$"
                      item-text="title"
                      item-value="id"
                      outlined
                      required
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      v-model="day.dinner_id"
                      label="Cena - Segundo plato"
                      :items="recipesDinner$"
                      item-text="title"
                      item-value="id"
                      outlined
                      clearable
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
      <div class="d-block mt-6">
        <v-btn class="text-white" color="secondary" to="/menus">
          <v-icon left dark>mdi-arrow-left</v-icon>Volver
        </v-btn>
        <v-btn
          v-if="dates.length"
          class="ml-4"
          depressed
          type="button"
          color="success"
          @click="create()"
          >Guardar</v-btn
        >
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../../store";
import {
  getWeekStartAndEndDates,
  getUserFriendlyDate,
} from "@/utils/weekCalendarDays";
import { fields } from "./menu.const";

export default {
  data() {
    return {
      tab: "Vegan",
      dates: [],
      veganMenus: [],
      flexMenus: [],
      valid: false,
      lazy: true,
      snackbar: false,
      getUserFriendlyDate,
    };
  },
  computed: {
    recipes$() {
      return store.getters["recipes/items"].map((recipe) => ({
        title: recipe.title,
        id: recipe.id,
        vegan: recipe.vegan,
      }));
    },
    getAlreadyCreatedMenus() {
      return store.getters["menus/items"];
    },
    recipesBreakfast$() {
      return store.getters["recipes/items"]
        .filter((recipe) => recipe.is_breakfast)
        .map((recipe) => ({
          title: recipe.title,
          id: recipe.id,
          vegan: recipe.vegan,
        }));
    },
    recipesLunch$() {
      return store.getters["recipes/items"]
        .filter((recipe) => recipe.is_lunch)
        .map((recipe) => ({
          title: recipe.title,
          id: recipe.id,
          vegan: recipe.vegan,
        }));
    },
    recipesSnack$() {
      return store.getters["recipes/items"]
        .filter((recipe) => recipe.is_snack)
        .map((recipe) => ({
          title: recipe.title,
          id: recipe.id,
          vegan: recipe.vegan,
        }));
    },
    recipesDinner$() {
      return store.getters["recipes/items"]
        .filter((recipe) => recipe.is_dinner)
        .map((recipe) => ({
          title: recipe.title,
          id: recipe.id,
          vegan: recipe.vegan,
        }));
    },
    error$() {
      return store.getters["menus/error"];
    },
  },
  mounted() {
    this.veganMenus = [{}, {}, {}, {}, {}, {}, {}];
    this.flexibleMenus = [{}, {}, {}, {}, {}, {}, {}];
  },
  methods: {
    allowedDates(val) {
      let disabledDates = [];
      for (let date of this.getAlreadyCreatedMenus) {
        disabledDates = [
          ...disabledDates,
          ...getWeekStartAndEndDates(date.first_date, true),
        ];
      }
      const day = new Date(val).getDay();
      const isWeekend = day === 6 || day === 0;
      return (
        (!disabledDates.includes(val) || this.dates.includes(val))
      );
    },
    save(date) {
      this.dates = [...getWeekStartAndEndDates(date)];
    },
    requiredRule(field) {
      return [(v) => !!v || `${field} is required`];
    },
    menu() {
      store.dispatch("user/ToggleMenu");
    },
    getStoreDates() {
      return [...this.dates, ...this.dates];
    },
    onFlexTabClicked() {
      this.flexibleMenus = [...this.flexibleMenus].map((value) => {
        value.vegan = false;
        return value;
      });
      this.veganMenus = [...this.veganMenus].map((value) => {
        value.vegan = true;
        return value;
      });
      for (let idx = 0; idx < 7; idx++) {
        fields.forEach((type) => {
          if (!this.flexibleMenus[idx][type]) {
            this.flexibleMenus[idx][type] = this.veganMenus[idx][type];
          }
        });
      }
    },
    fillPayload() {
      return [...this.veganMenus, ...this.flexibleMenus];
    },
    create() {
      this.tab = "Vegan";
      if (this.$refs.veganForm.validate()) {
        this.valid = true;
        this.snackbar = false;
        const storedDates = this.getStoreDates();
        this.fillPayload().forEach((menu, index) => {
          this.$store
            .dispatch("menus/CreateNewMenu", {
              menu,
              date: storedDates[index],
            })
            .then((data) => {
              if (data) this.$router.push("/menus");
            });
        });
      } else {
        this.valid = false;
        this.snackbar = true;
      }
    },
  },
};
</script>

<style></style>
