var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", app: "", color: "white", light: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.menu($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pr-2 pl-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-silverware-fork-knife")
              ])
            ],
            1
          ),
          _c("v-toolbar-title", [
            _c(
              "span",
              { staticStyle: { color: "#84264f", "font-weight": "bold" } },
              [_vm._v("Menús")]
            ),
            _vm._v(" / Editar menú\n    ")
          ])
        ],
        1
      ),
      _vm.error$.isError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(_vm._s(_vm.error$.message))
          ])
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          model: {
            value: _vm.updatedSuccess,
            callback: function($$v) {
              _vm.updatedSuccess = $$v
            },
            expression: "updatedSuccess"
          }
        },
        [
          _vm._v("\n    Menú guardado correctamente\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", text: "" },
              on: {
                click: function($event) {
                  _vm.updatedSuccess = false
                }
              }
            },
            [_vm._v("Cerrar")]
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v("\n    Hay campos que faltan por seleccionar\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v("Vale")]
          )
        ],
        1
      ),
      _vm.dates.length
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("h3", { staticClass: "mb-4" }, [_vm._v("Semana menú")]),
              _c("v-date-picker", {
                attrs: {
                  color: "primary",
                  multiple: "",
                  locale: "es-es",
                  "first-day-of-week": 1,
                  landscape: true,
                  "full-width": true,
                  "allowed-dates": _vm.allowedDates
                },
                on: { "click:date": _vm.save },
                model: {
                  value: _vm.dates,
                  callback: function($$v) {
                    _vm.dates = $$v
                  },
                  expression: "dates"
                }
              }),
              _c(
                "v-tabs",
                {
                  staticClass: "mt-6",
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab", { attrs: { href: "#vegan" } }, [
                    _vm._v("Vegano")
                  ]),
                  _c("v-tab", { attrs: { href: "#flexible" } }, [
                    _vm._v("Flexible")
                  ])
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { key: 1, attrs: { value: "vegan" } },
                    [
                      _vm.dates.length
                        ? _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "px-10",
                              attrs: { "lazy-validation": _vm.lazy },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            _vm._l(_vm.veganMenus, function(menu, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getUserFriendlyDate(
                                          _vm.dates[index]
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesBreakfast$.filter(
                                                function(ref) {
                                                  var vegan = ref.vegan

                                                  return vegan
                                                }
                                              ),
                                              label: "Desayuno",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.breakfast_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  menu,
                                                  "breakfast_id",
                                                  $$v
                                                )
                                              },
                                              expression: "menu.breakfast_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesLunch$.filter(
                                                function(ref) {
                                                  var vegan = ref.vegan

                                                  return vegan
                                                }
                                              ),
                                              label: "Comida - Primer plato",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.lunch_starter_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  menu,
                                                  "lunch_starter_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "menu.lunch_starter_id"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesLunch$.filter(
                                                function(ref) {
                                                  var vegan = ref.vegan

                                                  return vegan
                                                }
                                              ),
                                              label: "Comida - Segundo plato",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.lunch_id,
                                              callback: function($$v) {
                                                _vm.$set(menu, "lunch_id", $$v)
                                              },
                                              expression: "menu.lunch_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesSnack$.filter(
                                                function(ref) {
                                                  var vegan = ref.vegan

                                                  return vegan
                                                }
                                              ),
                                              label: "Merienda",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.snack_id,
                                              callback: function($$v) {
                                                _vm.$set(menu, "snack_id", $$v)
                                              },
                                              expression: "menu.snack_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesDinner$.filter(
                                                function(ref) {
                                                  var vegan = ref.vegan

                                                  return vegan
                                                }
                                              ),
                                              label: "Cena - Primer plato",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.dinner_starter_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  menu,
                                                  "dinner_starter_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "menu.dinner_starter_id"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesDinner$.filter(
                                                function(ref) {
                                                  var vegan = ref.vegan

                                                  return vegan
                                                }
                                              ),
                                              label: "Cena - Segundo plato",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.dinner_id,
                                              callback: function($$v) {
                                                _vm.$set(menu, "dinner_id", $$v)
                                              },
                                              expression: "menu.dinner_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: 2, attrs: { value: "flexible" } },
                    [
                      _vm.dates.length
                        ? _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "px-10",
                              attrs: { "lazy-validation": _vm.lazy },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            _vm._l(_vm.flexibleMenus, function(menu, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getUserFriendlyDate(
                                          _vm.dates[index]
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesBreakfast$,
                                              label: "Desayuno",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.breakfast_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  menu,
                                                  "breakfast_id",
                                                  $$v
                                                )
                                              },
                                              expression: "menu.breakfast_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesLunch$,
                                              label: "Comida - Primer plato",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.lunch_starter_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  menu,
                                                  "lunch_starter_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "menu.lunch_starter_id"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesLunch$,
                                              label: "Comida - Segundo plato",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.lunch_id,
                                              callback: function($$v) {
                                                _vm.$set(menu, "lunch_id", $$v)
                                              },
                                              expression: "menu.lunch_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesSnack$,
                                              label: "Merienda",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.snack_id,
                                              callback: function($$v) {
                                                _vm.$set(menu, "snack_id", $$v)
                                              },
                                              expression: "menu.snack_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesDinner$,
                                              label: "Cena - Primer plato",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.dinner_starter_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  menu,
                                                  "dinner_starter_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "menu.dinner_starter_id"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.recipesDinner$,
                                              label: "Cena - Segundo plato",
                                              "item-text": "title",
                                              "item-value": "id",
                                              outlined: "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateMenus()
                                              }
                                            },
                                            model: {
                                              value: menu.dinner_id,
                                              callback: function($$v) {
                                                _vm.$set(menu, "dinner_id", $$v)
                                              },
                                              expression: "menu.dinner_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-white mt-4",
                  attrs: { color: "secondary", to: "/menus" }
                },
                [
                  _c("v-icon", { attrs: { left: "", dark: "" } }, [
                    _vm._v("mdi-arrow-left")
                  ]),
                  _vm._v("Volver\n    ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }