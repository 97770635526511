<template>
  <div>
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <div class="pr-2 pl-2">
        <v-icon color="primary">mdi-silverware-fork-knife</v-icon>
      </div>

      <v-toolbar-title style="color:#84264f; font-weight:bold">
        Menús
        <v-chip color="primary" class="ml-4">{{ totalItems$ }}</v-chip>
      </v-toolbar-title>
      <v-spacer />
      <v-btn medium color="primary" to="/menus/nuevo">Añadir menú</v-btn>
    </v-app-bar>

    <v-alert v-if="error$.isError" type="error">{{ error$.message }}</v-alert>

    <v-container fluid>
      <v-dialog v-model="delDialog" max-width="290">
        <v-card>
          <v-card-title>Borrar</v-card-title>
          <v-card-text class="subtitle-1">Deseas borrar este menú ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="delDialog = false">Cancelar</v-btn>
            <v-btn color="primary" @click="deleteMenu()">Sí</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            dense
            prepend-inner-icon="mdi-magnify"
            background-color="red"
            label="Buscar menú"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-toolbar color="secondary" dark flat dense>
        <v-row>
          <v-col cols="12" sm="10">
            <div class="body-1">Nombre</div>
          </v-col>
          <v-col cols="12" sm="2">
            <div></div>
          </v-col>
        </v-row>
      </v-toolbar>

      <ItemList
        :items="menus$"
        :titleAction="{ type: 'EDIT_MENU', icon: 'mdi-pencil' }"
        :actions="[
          { type: 'VIEW_SHOPPING_LIST', icon: 'mdi-format-list-checks' },
          { type: 'VIEW_BATCH_COOKING', icon: 'mdi-fridge' },
          { type: 'DELETE_MENU', icon: 'mdi-delete' },
        ]"
        type="menu"
        :dragAndDropActive="true"
        :totalItems="totalItems$"
        @actionTrigger="onActionTrigger($event)"
        @paginationChange="paginationChange($event)"
      />
    </v-container>
  </div>
</template>

<script>
import store from '../../store';
import ItemList from '@/components/itemList';
import * as CryptoJS from 'crypto-js';
import { TABLE_NUM_ITEMS } from '../../constants';

export default {
  data() {
    return {
      delDialog: false,
      searchIngredients: null,
      filter: null,
    };
  },
  components: { ItemList },
  computed: {
    menus$() {
      return store.getters['menus/items'];
    },
    totalItems$() {
      return parseInt(store.getters['menus/totalItems']);
    },
    error$() {
      return store.getters['menus/error'];
    },
  },
  methods: {
    menu() {
      store.dispatch('user/ToggleMenu');
    },
    onActionTrigger({ event }) {
      switch (event.action) {
        case 'EDIT_MENU':
          this.$router.push(`/menus/editar/${event.id}`);
          break;
        case 'DELETE_MENU':
          this.delDialog = true;
          this.itemIdToDelete = event.id;
          break;
        case 'VIEW_SHOPPING_LIST':
          this.previewShoppingList(event.id);
          break;
        case 'VIEW_BATCH_COOKING':
          this.previewBatchCooking(event.id);
          break;
      }
    },
    previewShoppingList(id) {
      let token = localStorage.getItem('user-token') || '';
      let encodedSecret = this.enc(token);
      const baseUrlFront = process.env.VUE_APP_FRONT_END_URL;
      let win = window.open(
        baseUrlFront + `/preview-shopping-list/${id}/?token=` + encodedSecret,
        '_blank'
      );
      win.focus();
    },
    previewBatchCooking(id) {
      let token = localStorage.getItem('user-token') || '';
      let encodedSecret = this.enc(token);
      const baseUrlFront = process.env.VUE_APP_FRONT_END_URL;
      let win = window.open(
        baseUrlFront + `/preview-batch-cooking/${id}/?token=` + encodedSecret,
        '_blank'
      );
      win.focus();
    },
    enc(plainText) {
      let b64 = CryptoJS.AES.encrypt(
        plainText,
        process.env.VUE_APP_SECRET
      ).toString();
      let e64 = CryptoJS.enc.Base64.parse(b64);
      let eHex = e64.toString(CryptoJS.enc.Hex);
      return eHex;
    },
    paginationChange(data) {
      store.dispatch('menus/LoadMenus', {
        offset: data.event * TABLE_NUM_ITEMS,
        limit: TABLE_NUM_ITEMS,
      });
    },
    deleteMenu() {
      store
        .dispatch('menus/DeleteMenu', { id: this.itemIdToDelete })
        .then(() => {
          this.delDialog = false;
        });
    },
  },
};
</script>

<style></style>
